import { createRouter, createWebHashHistory } from 'vue-router';


const routes = [
    { path: '/', component: () => import("../view/Login.vue") },
    { path: '/aesRecord', component: () => import("../view/AesDecode.vue") },
]

const router = createRouter({
    history:createWebHashHistory('/'),
    routes
})

export default router
