<template>
  <AesDecode msg="Welcome to Your Vue.js App"/>
  <router-view></router-view>
</template>

<script>

// import HelloWorld from './components/HelloWorld.vue'
// import AesDecode from './view/AesDecode.vue'
// import Login from './view/Login.vue'

export default {
  name: 'App',
  components: {
    // AesDecode,
    // Login
  }
}
</script>

<style>
#app {
  /*font-family: Avenir, Helvetica, Arial, sans-serif;*/
  /*-webkit-font-smoothing: antialiased;*/
  /*-moz-osx-font-smoothing: grayscale;*/
  /*text-align: center;*/
  /*color: #2c3e50;*/
  /*margin-top: 60px;*/
}
</style>
